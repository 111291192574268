//auth actions

export const REGISTER = "REGISTER";

export const LOGIN = "LOGIN";

export const ACTIVATION = "ACTIVATION";

export const RESET_PASSWORD_FIRST = "RESET_PASSWORD_FIRST";

export const RESET_PASSWORD_SECOND = "RESET_PASSWORD_SECOND";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";

//user actions

export const USER = "USER";
export const USER_SUCCESS = "USER_SUCCESS";
export const USER_FAIL = "USER_FAIL";

export const NOTIFICATION = "NOTIFICATION";
export const NOTIFICATION_SUCCESS = "NOTIFICATION_SUCCESS";
export const NOTIFICATION_FAIL = "NOTIFICATION_FAIL";

export const DASHBOARD = "DASHBOARD";
export const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS";
export const DASHBOARD_FAIL = "DASHBOARD_FAIL";

export const INFO = "INFO";
export const INFO_SUCCESS = "INFO_SUCCESS";
export const INFO_FAIL = "INFO_FAIL";

export const CHANGE_INFO = "CHANGE_INFO";
export const CHANGE_INFO_SUCCESS = "CHANGE_INFO_SUCCESS";
export const CHANGE_INFO_FAIL = "CHANGE_INFO_FAIL";

export const INFO_CONTACTS = "INFO_CONTACTS";
export const INFO_CONTACTS_SUCCESS = "INFO_CONTACTS_SUCCESS";
export const INFO_CONTACTS_FAIL = "INFO_CONTACTS_FAIL";

export const NEW_CONTACT = "NEW_CONTACT";
export const NEW_CONTACT_SUCCESS = "NEW_CONTACT_SUCCESS";
export const NEW_CONTACT_FAIL = "NEW_CONTACT_FAIL";

export const CHANGE_CONTACT = "CHANGE_CONTACT";
export const CHANGE_CONTACT_SUCCESS = "CHANGE_CONTACT_SUCCESS";
export const CHANGE_CONTACT_FAIL = "CHANGE_CONTACT_FAIL";

export const DELETE_CONTACT = "DELETE_CONTACT";
export const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS";
export const DELETE_CONTACT_FAIL = "DELETE_CONTACT_FAIL";

export const INFO_ADDRESSES = "INFO_ADDRESSES";
export const INFO_ADDRESSES_SUCCESS = "INFO_ADDRESSES_SUCCESS";
export const INFO_ADDRESSES_FAIL = "INFO_ADDRESSES_FAIL";

export const NEW_ADDRESS = "NEW_ADDRESS";
export const NEW_ADDRESS_SUCCESS = "NEW_ADDRESS_SUCCESS";
export const NEW_ADDRESS_FAIL = "NEW_ADDRESS_FAIL";

export const CHANGE_ADDRESS = "CHANGE_ADDRESS";
export const CHANGE_ADDRESS_SUCCESS = "CHANGE_ADDRESS_SUCCESS";
export const CHANGE_ADDRESS_FAIL = "CHANGE_ADDRESS_FAIL";

export const DELETE_ADDRESS = "DELETE_ADDRESS";
export const DELETE_ADDRESS_SUCCESS = "DELETE_ADDRESS_SUCCESS";
export const DELETE_ADDRESS_FAIL = "DELETE_ADDRESS_FAIL";

///////////////// BlackList //////////////////////

export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_FAIL = "GET_PRODUCTS_FAIL";

export const GET_BLACKLIST_OFFERS = "GET_BLACKLIST_OFFERS";
export const GET_BLACKLIST_OFFERS_SUCCESS = "GET_BLACKLIST_OFFERS_SUCCESS";
export const GET_BLACKLIST_OFFERS_FAIL = "GET_BLACKLIST_OFFERS_FAIL";

export const POST_PRODUCT_TO_BLACKLIST = "POST_PRODUCT_TO_BLACKLIST";
export const POST_PRODUCT_TO_BLACKLIST_SUCCESS =
    "POST_PRODUCT_TO_BLACKLIST_SUCCESS";
export const POST_PRODUCT_TO_BLACKLIST_FAIL = "POST_PRODUCT_TO_BLACKLIST_FAIL";

export const DELETE_PRODUCT_FROM_BLACKLIST = "DELETE_PRODUCT_FROM_BLACKLIST";
export const DELETE_PRODUCT_FROM_BLACKLIST_SUCCESS =
    "DELETE_PRODUCT_FROM_BLACKLIST_SUCCESS";
export const DELETE_PRODUCT_FROM_BLACKLIST_FAIL =
    "DELETE_PRODUCT_FROM_BLACKLIST_FAIL";

//reviews actions

export const REVIEWS = "REVIEWS";
export const REVIEWS_SUCCESS = "REVIEWS_SUCCESS";
export const REVIEWS_FAIL = "REVIEWS_FAIL";

export const REVIEWS_RESPONSE = "REVIEWS_RESPONSE";
export const REVIEWS_RESPONSE_SUCCESS = "REVIEWS_RESPONSE_SUCCESS";
export const REVIEWS_RESPONSE_FAIL = "REVIEWS_RESPONSE_FAIL";

//price list actions

export const PRICE_LIST_ADDRESSES = "PRICE_LIST_ADDRESSES";
export const PRICE_LIST_ADDRESSES_SUCCESS = "PRICE_LIST_ADDRESSES_SUCCESS";
export const PRICE_LIST_ADDRESSES_FAIL = "PRICE_LIST_ADDRESSES_FAIL";

export const SEND_FILE = "SEND_FILE";

export const SET_PRICE_LIST_INFO = "SET_PRICE_LIST_INFO";

export const CREATE_PRICE_LIST = "CREATE_PRICE_LIST";
export const CREATE_PRICE_LIST_SUCCESS = "CREATE_PRICE_LIST_SUCCESS";
export const CREATE_PRICE_LIST_FAIL = "CREATE_PRICE_LIST_FAIL";

export const PRICE_LISTS = "PRICE_LISTS";
export const PRICE_LISTS_SUCCESS = "PRICE_LISTS_SUCCESS";
export const PRICE_LISTS_FAIL = "PRICE_LISTS_FAIL";
export const PRICE_LISTS_SELLERS = 'PRICE_LISTS_SELLERS';
export const SEND_PRICE_LISTS_SELLERS = 'SEND_PRICE_LISTS_SELLERS';

export const PATCH_PRICE_LIST = "PATCH_PRICE_LIST";
export const PATCH_PRICE_LIST_SUCCESS = "PATCH_PRICE_LIST_SUCCESS";
export const PATCH_PRICE_LIST_FAIL = "PATCH_PRICE_LIST_FAIL";

export const DELETE_PRICE_LIST = "DELETE_PRICE_LIST";
export const DELETE_PRICE_LIST_SUCCESS = "DELETE_PRICE_LIST_SUCCESS";
export const DELETE_PRICE_LIST_FAIL = "DELETE_PRICE_LIST_FAIL";

export const POST_SEND_FILE = "POST_SEND_FILE ";
export const POST_SEND_FILE_SUCCESS = "POST_SEND_FILE_SUCCESS";
export const POST_SEND_FILE_FAIL = "POST_SEND_FILE_FAIL";

export const CHANGE_MARKUP_PRICE_LIST = "CHANGE_MARKUP_PRICE_LIST";
export const CHANGE_MARKUP_PRICE_LIST_SUCCESS =
    "CHANGE_MARKUP_PRICE_LIST_SUCCESS";

export const DOWNLOAD_PRICE_LIST = "DOWNLOAD_PRICE_LIST";
export const DOWNLOAD_PRICE_LIST_SUCCESS = "DOWNLOAD_PRICE_LIST_SUCCESS";

export const IMPORT_ORDERS = "IMPORT_ORDERS";
export const IMPORT_ORDERS_SUCCESS = "IMPORT_ORDERS_SUCCESS";
export const IMPORT_ORDERS_FAIL = "IMPORT_ORDERS_FAIL";

export const PATH_CHANGE_SUPPLIER = "PATH_CHANGE_SUPPLIER";
export const PATH_CHANGE_SUPPLIERS_SUCCESS = "PATH_CHANGE_SUPPLIERS_SUCCESS";
export const PATH_CHANGE_SUPPLIER_FAIL = "PATH_CHANGE_SUPPLIER_FAIL";

//orders actions

export const GET_ORDERS = "GET_ORDERS";
export const GET_ORDERS_BY_NUMBER = "GET_ORDERS_BY_NUMBER";
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";
export const GET_ORDERS_FAIL = "GET_ORDERS_FAIL";

export const GET_ORDER = "GET_ORDER";
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";
export const GET_ORDER_FAIL = "GET_ORDER_FAIL";

export const CHANGE_ORDER_LOCAL = "CHANGE_ORDER_LOCAL";
export const CHANGE_ORDER_LOCAL_SUCCESS = "CHANGE_ORDER_LOCAL_SUCCESS";
export const CHANGE_ORDER_LOCAL_FAIL = "CHANGE_ORDER_LOCAL_FAIL";

export const PATCH_STATUS_ORDER = "PATCH_STATUS_ORDER";

//transactions actions

export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const GET_TRANSACTIONS_SUCCESS = "GET_TRANSACTIONS_SUCCESS";

export const GET_COMPANIES = "GET_COMPANIES";
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS";

export const POST_TRANSACTION = "POST_TRANSACTION";

//refundRequests actions

export const GET_REFUND_REQUESTS = "GET_REFUND_REQUESTS";
export const GET_REFUND_REQUESTS_SUCCESS = "GET_REFUND_REQUESTS_SUCCESS";

export const POST_REFUND_REQUEST = "POST_REFUND_REQUEST";

export const GET_SPECIFIC_REFUND_REQUEST = "GET_SPECIFIC_REFUND_REQUEST";
export const GET_SPECIFIC_REFUND_REQUEST_SUCCESS =
    "GET_SPECIFIC_REFUND_REQUEST_SUCCESS";

export const PATCH_REFUND_REQUEST_STATUS = "PATCH_REFUND_REQUEST_STATUS";
export const POST_REFUND_REQUEST_CONFIRM_STATUS = "POST_REFUND_REQUEST_CONFIRM_STATUS";
export const POST_DIALOG_MESSAGE = "POST_DIALOG_MESSAGE";

export const POST_REFUND_REQUESTS_OFFERS = "POST_REFUND_REQUESTS_OFFERS";
export const POST_REFUND_REQUESTS_SUCCESS_OFFERS = "POST_REFUND_REQUESTS_SUCCESS_OFFERS";

//goodsReception actions

export const POST_INVOICE_FILE = "POST_INVOICE_FILE";
export const POST_INVOICE_FILE_SUCCESS = "POST_INVOICE_FILE_SUCCESS";
export const POST_INVOICE_FILE_FAIL = "POST_INVOICE_FILE_FAIL";

export const GET_RECEPTION_HISTORY = "GET_RECEPTION_HISTORY";
export const GET_RECEPTION_HISTORY_SUCCESS = "GET_RECEPTION_HISTORY_SUCCESS";

export const POST_ACCEPTED_ORDERS = "POST_ACCEPTED_ORDERS";
export const GET_MEXCAR_AUTO = "GET_MEXCAR_AUTO";
export const GET_MEXCAR_AUTO_SUCCESS = "GET_MEXCAR_AUTO_SUCCESS";

export const ORDER_MEXCAR_AUTO = "ORDER_MEXCAR_AUTO";

export const FILE_TYPE_LIST = "FILE_TYPE_LIST";

export const SEND_ORDERS_TO_REJECT = 'SEND_ORDERS_TO_REJECT';

export const GET_WAITING_RECEPTION_GOODS = 'GET_WAITING_RECEPTION_GOODS';
export const GET_WAITING_RECEPTION_GOODS_SUCCESS = 'GET_WAITING_RECEPTION_GOODS_SUCCESS';

//disposalBalances actions

export const SEARCH_COMPANY = "SEARCH_COMPANY";
export const SEARCH_COMPANY_SUCCESS = "SEARCH_COMPANY_SUCCESS";

export const GET_COMPANIES_GOODS = "GET_COMPANIES_GOODS";
export const GET_COMPANIES_GOODS_SUCCESS = "GET_COMPANIES_GOODS_SUCCESS";

export const POST_NEW_RESIDUE = "POST_NEW_RESIDUE";
export const POST_NEW_RESIDUE_SUCCESS = "POST_NEW_RESIDUE_SUCCESS";

export const GET_ALL_RESIDUES = "GET_ALL_RESIDUES";
export const GET_ALL_RESIDUES_SUCCESS = "GET_ALL_RESIDUES_SUCCESS";

export const GET_CHANGE_SUPPLIER = "GET_CHANGE_SUPPLIER";
export const GET_CHANGE_SUPPLIER_SUCCESS = "GET_CHANGE_SUPPLIER_SUCCESS";
export const GET_CHANGE_SUPPLIER_FAIL = "GET_CHANGE_SUPPLIER_FAIL";

export const POST_CONFIRM_IMPORT = "POST_CONFIRM_IMPORT";
export const POST_CONFIRM_IMPORT_SUCCESS = "POST_CONFIRM_IMPORT_SUCCESS";
export const POST_CONFIRM_IMPORT_FAIL = "POST_CONFIRM_IMPORT_FAIL";

export const GET_SUPPLIERS = "GET_SUPPLIERS";
export const GET_SUPPLIERS_SUCCESS = "GET_SUPPLIERS_SUCCESS";
export const GET_SUPPLIERS_IMPORT_FAIL = "GET_SUPPLIERS_IMPORT_FAIL";

export const GET_SUPPLIERS_AUTO = "GET_SUPPLIERS_AUTO";
export const GET_SUPPLIERS_AUTO_SUCCESS = "GET_SUPPLIERS_AUTO_SUCCESS";

//providers actions

export const GET_PROVIDERS = "GET_PROVIDERS";
